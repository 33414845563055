.container {
  display: flex;
  margin-top: 50px;
}

.left_part {
  width: 30%;
  text-align: center;
}

.right_part {
  width: 70%;
}

.left_part img {
  width: 70%;
}

.title {
  font-weight: 500;
  font-size: 26px;
  position: relative;
  margin-bottom: 20px;
  width: fit-content;
}

.title::after {
  content: ' ';
  width: 90%;
  height: 2px;
  /* background-color: rgb(148, 148, 148); */
  background-color: #2196f3;
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 0;
}
.description {
  font-size: 14px;
  max-width: 80%;
}
.infoTable {
  min-width: 300px;
  max-width: 70%;
}
